<template>
  <v-row justify="center">
    <v-dialog ref="dialog" v-model="dialog" :return-value.sync="lesson" persistent max-width="800px"
      @keydown.esc="close()" :fullscreen="$helpers.display.mobileDialog" :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            lesson.id == null
              ? $helpers.string.capitalizeI18N("class.creation")
              : lesson.name
          }}</span>
          <v-switch class="ml-4" v-model="lesson.active" :label="$helpers.string.capitalizeI18N('active')"
            v-if="lesson.id"></v-switch>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row align="center">
                  <ColorPicker v-model="lesson.color" ref="colorPicker" />
                  <span class="ml-4 text-body-2">Choisissez la couleur associée à ce cours. Cette couleur
                    sera affichée dans votre planning.</span>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('name', {
                      required: true,
                    })
                      " v-model="lesson.name" required autofocus prepend-icon="mdi-label"
                      :rules="$helpers.rule.notEmpty()"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('public-name')" v-model="lesson.activityName"
                      prepend-icon="mdi-web"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <TimePicker v-model="lesson.timeBegin" :label="$helpers.string.capitalizeI18N('hour-begin')"
                      clearable />
                  </v-col>
                  <v-col>
                    <TimePicker v-model="lesson.timeEnd" :label="$helpers.string.capitalizeI18N('hour-end')"
                      clearable />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox :items="['weekly']" :label="$helpers.string.capitalizeI18N('weekly')"
                      v-model="lesson.frequency" true-value="weekly" :false-value="null"
                      prepend-icon="mdi-rotate-right" />
                  </v-col>
                  <v-col v-show="lesson.frequency == 'weekly'">
                    <div>
                      <v-checkbox class="d-inline" v-model="lesson.monday" :label="getWeekdayName(1)" />
                      <v-checkbox class="d-inline" v-model="lesson.tuesday" :label="getWeekdayName(2)" />
                      <v-checkbox class="d-inline" v-model="lesson.wednesday" :label="getWeekdayName(3)" />
                      <v-checkbox class="d-inline" v-model="lesson.thursday" :label="getWeekdayName(4)" />
                      <v-checkbox class="d-inline" v-model="lesson.friday" :label="getWeekdayName(5)" />
                      <v-checkbox class="d-inline" v-model="lesson.saturday" :label="getWeekdayName(6)" />
                      <v-checkbox class="d-inline" v-model="lesson.sunday" :label="getWeekdayName(7)" />
                    </div>

                    <!-- <v-select
                      v-show="lesson.frequency == 'weekly'"
                      :items="[1, 2, 3, 4, 5, 6, 7]"
                      :label="
                        $helpers.string.capitalizeI18N('weekday', {
                          required: lesson.frequency != null,
                        })
                      "
                      v-model="lesson.weekday"
                      prepend-icon="mdi-calendar-today"
                      clearable
                      multiple
                      :error="errors['weekday']"
                      :required="lesson.frequency != null"
                    >
                      <template v-slot:selection="data">
                        {{ getWeekdayName(data.item) }}
                      </template>
<template v-slot:item="data">
                        {{ getWeekdayName(data.item) }}
                      </template>
</v-select> -->
                  </v-col>
                  <!-- <v-select
                      :items="['weekly']"
                      :label="$helpers.string.capitalizeI18N('frequency')"
                      v-model="lesson.frequency"
                      prepend-icon="mdi-rotate-right"
                      clearable
                    >
                      <template v-slot:selection="data">
                        {{ getFrequencyName(data.item) }}
                      </template>
                      <template v-slot:item="data">
                        {{ getFrequencyName(data.item) }}
                      </template>
                    </v-select> -->
                </v-row>
                <v-row>
                  <v-col>
                    <SelectableSite v-model="lesson.siteId" />
                  </v-col>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('maximum-unit')" v-model="lesson.maximumUnit"
                      type="number" :placeholder="$helpers.string.capitalizeI18N('no-limit')" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <SelectableUser v-model="lesson.userId" />
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <SelectableMembershipType v-model="lesson.membershipTypeId" label="Réservation rapide" />
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col>
                    <picker-area v-model="lesson.description" :placeholder="$helpers.string.capitalizeI18N('description')
                      " />
                  </v-col>
                </v-row>
                <v-row no-gutters align="center" v-if="showLimitMemberships">
                  <v-col class="shrink mr-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-information</v-icon>
                      </template>
                      <span
                        v-html="'Si vous voulez limiter l\'accès à ce cours à certains types d\'abonnement, cochez cette case et sélectionnez les ci-dessous.'"></span>
                    </v-tooltip>
                  </v-col>
                  <v-col><v-checkbox v-model="limitMembershipTypes" :label="$helpers.string.capitalizeI18N(
                    'membership-type.multiple-limit'
                  )
                    " />
                  </v-col>

                </v-row>
                <v-row no-gutters v-if="showLimitMemberships">
                  <v-col>
                    <SelectableMembershipTypes solo v-show="limitMembershipTypes" v-model="lesson.membership_types" />
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton @click="saveAndClose" type="save" ref="saveButton" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import ColorPicker from "@/components/Admin/ColorPicker";
import SelectableSite from "../Site/SelectableSite";
import { mask } from "vue-the-mask";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import SelectableUser from "@/components/Admin/User/SelectableUser";
import TimePicker from "@/components/Admin/TimePicker";
import SelectableMembershipTypes from "@/components/Admin/MembershipType/SelectableMembershipTypes";
import PickerArea from "vue-emoji-mart-picker";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";
import CardActionButton from "@/components/Admin/Card/CardActionButton.vue";
// import SelectableMembershipType from "../MembershipType/SelectableMembershipType.vue";


export default {
  name: "LessonEditionPopup",
  props: {
    showLimitMemberships: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      dialog: false,
      lesson: {},
      menu: false,
      errors: [],
      limitMembershipTypes: false,
    };
  },
  directives: {
    mask,
  },
  components: {
    ColorPicker,
    SelectableSite,
    FormGroupElement,
    SelectableUser,
    TimePicker,
    SelectableMembershipTypes,
    PickerArea,
    CardActionButton,
    PopupCloseButton,
    // SelectableMembershipType,
  },
  watch: {
    limitMembershipTypes: function (val) {
      if (!val) {
        this.lesson.membership_types = [];
      }
    },
  },
  methods: {
    getWeekdayName(weekday) {
      return this.$helpers.string.capitalize(
        this.$helpers.date.getWeekdayName(weekday)
      );
    },
    getFrequencyName(frequency) {
      let name;

      switch (frequency) {
        case "weekly":
          name = "weekly";
          break;
        default:
          name = "none";
          break;
      }

      return this.$helpers.string.capitalizeI18N(name);
    },
    async saveLesson() {
      try {
        this.lesson.weekday =
          this.lesson.weekday === undefined ? null : this.lesson.weekday;

        const response = await this.$services.lesson.save(this.lesson);

        this.lesson = response.data;

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadLesson() {
      if (!this.lesson.color) this.lesson.color = "#F1AA67";

      if (this.$refs.colorPicker)
        this.$refs.colorPicker.color = this.lesson.color;

      this.lesson.description = this.lesson.description ?? "";

      if (!this.lesson.membership_types)
        this.lesson.membership_types = [];

      this.limitMembershipTypes =
        this.lesson.membership_types?.length > 0 ?? false;
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        this.errors = [];

        if (!this.lesson.frequency) {
          this.lesson.monday = false;
          this.lesson.tuesday = false;
          this.lesson.wednesday = false;
          this.lesson.thursday = false;
          this.lesson.friday = false;
          this.lesson.saturday = false;
          this.lesson.sunday = false;
        }

        if (Object.keys(this.errors).length > 0) return;

        if (!this.$refs.form.validate()) return;

        await this.saveLesson();

        this.$helpers.snackbar.showSuccessI18N("class.save.success");

        this.$emit("onSaveClicked", this.lesson);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.saveLesson();
      this.$emit("onSaveClicked", this.lesson);
      this.lesson = {};
      this.loadLesson();
      this.$refs.name.focus();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(lesson) {
      this.lesson = lesson;
      this.loadLesson();
      this.dialog = true;
    },
  },
};
</script>