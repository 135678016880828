<template>
  <v-container>
    <v-row align="center" justify="center" no-gutters>
      <!-- <v-row align="center" justify="center"> -->
      <v-col md="6">
        <DashboardWelcome ref="dashboardWelcome" />
      </v-col>
    </v-row>
    <!-- <v-row no-gutters align="center" justify="end" v-show="showHelpButton">
      <v-col class="text-right"
        ><v-btn text @click="showHelp()">revoir l'aide</v-btn>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col>
        <DashboardOrganization />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DashboardReferral />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DashboardPlanning />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DashboardMemberships />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardPlanning from "@/components/Admin/Dashboard/DashboardPlanning";
import DashboardWelcome from "@/components/Admin/Dashboard/DashboardWelcome";
import DashboardOrganization from "@/components/Admin/Dashboard/DashboardOrganization";
import DashboardMemberships from "@/components/Admin/Dashboard/DashboardMemberships";
import DashboardReferral from "@/components/Admin/Dashboard/DashboardReferral";

export default {
  components: {
    DashboardPlanning,
    DashboardWelcome,
    DashboardOrganization,
    DashboardMemberships,
    DashboardReferral,
  },
  data() {
    return {
      showHelpButton: false,
    };
  },
  computed: {},
  async mounted() {
    this.showHelpButton = localStorage.getItem("hide-welcome") ?? false;
  },
  methods: {
    showHelp() {
      localStorage.removeItem("hide-welcome");

      this.$refs.dashboardWelcome.showWelcome = true;
      this.$refs.dashboardWelcome.alert = true;
      this.showHelpButton = false;
    },
  },
};
</script>