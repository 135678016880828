<template>
  <v-select
    :items="sites"
    :label="$helpers.string.capitalize($tc('site.site', 1), { required: $attrs['required'] != null })"
    v-bind="$attrs"
    v-on="$listeners"
    item-value="id"
    :item-text="getFullName"
    :placeholder="$helpers.string.capitalize($tc('site.site', 0))"
    prepend-icon="mdi-crosshairs"
    clearable
  ></v-select>
<!--   
  <v-layout>
    <v-autocomplete
      :items="sites"
      v-bind="$attrs"
      v-on="$listeners"
      item-value="id"
      :item-text="getFullName"
      :label="$helpers.string.capitalizeI18N('site')"
      ref="sitesSelect"
      @keydown.enter="enterPressed($event)"
      :readonly="$attrs['value'] != null"
      :search-input.sync="siteName"
    >
      <template v-slot:append-outer>
        <v-btn icon @click="clearClicked" :disabled="isEditDisabled()" tabindex="-1">
          <v-icon>close</v-icon>
        </v-btn>
        <v-btn icon @click="editClicked" :disabled="isEditDisabled()" tabindex="-1">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon @click="addClicked" tabindex="-1">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <i18n path="no-matching-create" tag="p">
                <template v-slot:search>
                  <span class="font-weight-bold">{{ siteName }}</span>
                </template>
                <template v-slot:enterKey>
                  <kbd>enter</kbd>
                </template>
              </i18n>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <SiteEditionPopup ref="siteEdition" @onSaveClicked="saveClicked" />
  </v-layout> -->
</template>

<script>
//import SiteEditionPopup from "./SiteEditionPopup.vue";

export default {
  name: "SelectableSite",
  data() {
    return {
      siteName: "",
      sites: [],
    };
  },
  components: {
    //SiteEditionPopup,
  },
  mounted() {
    this.retrieveSites();
  },
  methods: {
    getFullName(site) {
      return site.name;
    },
    isEditDisabled() {
      return this.$attrs["value"] == null;
    },
    enterPressed() {
      if (
        this.$refs.sitesSelect.computedItems.length == 0 &&
        this.siteName != null
      ) {
        this.saveClicked({ firstName: this.siteName }).then(() => {
          this.$refs.sitesSelect.blur();
        });
      }
    },
    clearClicked() {
      this.$attrs["value"] = null;
      this.$emit("input", this.$attrs["value"]);
      this.$refs.sitesSelect.focus();
    },
    addClicked() {
      this.$refs.siteEdition.open({});
    },
    editClicked() {
      this.$services.site
        .get(this.$refs.sitesSelect.value)
        .then((response) => {
          this.$refs.siteEdition.open(response.data);
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("sites.error.notfound");
        });
    },
    retrieveSites() {
      return this.$services.site
        .getAll()
        .then((response) => {
          this.sites = response.data;
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("sites.error.retrieve");
        });
    },
    saveClicked(value) {
      this.retrieveSites().then(() => {
        this.$attrs["value"] = value.id;
        this.$emit("input", this.$attrs["value"]);
        this.$emit("change", this.$attrs["value"]);
      });
    },
  },
};
</script>