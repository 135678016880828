<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-container>
        <v-row align="center" justify="start" class="text-h5">
          <v-col> Échéances des abonnements</v-col>
          <v-col md="3">
            <v-select
              v-model="nbUnitLeft"
              :items="units"
              solo
              hide-details
              :item-text="getNbUnitLeftName"
              item-value="unit"
              prepend-inner-icon="mdi-calendar"
              :loading="loading"
            ></v-select>
          </v-col>
          <v-col class="shrink">ou</v-col>
          <v-col md="3">
            <v-select
              v-model="period"
              :items="periods"
              solo
              hide-details
              prepend-inner-icon="mdi-calendar"
              :loading="loading"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container v-if="memberships.length > 0">
        <v-row
          v-for="(membership, index) in memberships"
          :key="index"
          class="text-h6"
        >
          <!-- {{ membership }} -->
          <v-col md="1" class="shrink">
            <UsedUnitsChip
              :usedUnits="Number(membership.totalUnit)"
              :totalUnits="Number(membership.membership_type.unit)"
            />
          </v-col>
          <v-col>
            {{ getFullClientName(membership.client) }}
          </v-col>
          <v-col>
            {{ membership.membership_type.name }}
          </v-col>
          <v-col md="1" class="text-center">
            <payment-status-chip
              class="ml-2"
              small
              :payment="membership.payment"
            />
          </v-col>
          <v-col>
            <v-icon v-show="membership.dateEnd" class="mr-1"
              >mdi-calendar-clock</v-icon
            >
            {{ $helpers.date.getDateDisplay(membership.dateEnd) }}
            <v-chip
              v-show="showOutOfDate(membership)"
              outlined
              color="error"
              label
              x-small
              class="text-uppercase ml-2"
              >{{ $t("expired") }}</v-chip
            >
          </v-col>
          <v-col class="shrink text-align-right">
            <v-btn @click="editMembership(membership)" icon small>
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col>
            {{ $helpers.string.capitalizeI18N("dashboard.no-expired-memberships") }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <MembershipEditionPopup
      ref="membershipEdition"
      @onSaveClicked="saveClicked"
      @onCloseClicked="retrieveAll"
    />
  </v-card>
</template>

<script>
import UsedUnitsChip from "@/components/Admin/Unit/UsedUnitsChip";
import MembershipEditionPopup from "@/components/Admin/Membership/MembershipEditionPopup";
import PaymentStatusChip from "@/components/Admin/Payment/PaymentStatusChip.vue";

export default {
  name: "DashboardMemberships",
  components: {
    UsedUnitsChip,
    MembershipEditionPopup,
    PaymentStatusChip,
  },
  data() {
    return {
      memberships: [],
      periods: [
        // {
        //   value: "last-30-days",
        //   text: this.$t("dashboard.last-30-days-expiration"),
        // },
        // {
        //   value: "last-7-days",
        //   text: this.$t("dashboard.last-7-days-expiration"),
        // },
        {
          value: "next-7-days",
          text: this.$t("dashboard.next-7-days-expiration"),
        },
        {
          value: "next-30-days",
          text: this.$t("dashboard.next-30-days-expiration"),
        },
      ],
      units: [0, 1, 2, 3, 4, 5].map((u) => {
        return { unit: u };
      }),
      period: "next-7-days",
      nbUnitLeft: 1,
      loading: true,
    };
  },
  computed: {},
  watch: {
    nbUnitLeft: function () {
      this.retrieveAll();
    },
    period: function () {
      this.retrieveAll();
    },
  },
  methods: {
    showOutOfDate(membership) {
      return new Date() > this.$moment(membership.dateEnd).toDate();
    },
    getNbUnitLeftName: (item) =>
      item.unit == 0
        ? "Aucune séance restante"
        : item.unit == 1
        ? `Moins d'une séance restante`
        : `Moins de ${item.unit} séances restantes`,
    getFullClientName(client) {
      const fullName = (
        (client.firstName ?? "") +
        " " +
        (client.lastName ?? "")
      ).trim();

      return client.companyName
        ? fullName
          ? `${fullName} (${client.companyName})`
          : client.companyName
        : fullName;
    },
    saveClicked() {
      this.retrieveAll();
    },
    editMembership(membership) {
      this.$services.membership
        .get(membership.id)
        .then((response) => {
          var value = JSON.parse(JSON.stringify(response.data));
          this.$refs.membershipEdition.open(value);
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    async retrieveAll() {
      this.loading = true;

      const from = this.$moment(new Date());
      let to = this.$moment(new Date());

      switch (this.period) {
        case "next-7-days":
          to = to.add({ days: 7 });
          break;
        case "next-30-days":
          to = to.add({ days: 30 });
          break;
        case "last-7-days":
          to = to.add({ days: -7 });
          break;
        case "last-30-days":
          to = to.add({ days: -30 });
          break;
      }

      if (to > from) {
        from.set({ hour: 0, minute: 0, second: 0 });
        to.set({ hour: 23, minute: 59, second: 59 });
      } else {
        from.set({ hour: 23, minute: 59, second: 59 });
        to.set({ hour: 0, minute: 0, second: 0 });
      }

      this.$services.membership
        .getAllExpiring({
          from:
            to > from
              ? from.toDate().toISOString().substr(0, 10)
              : to.toDate().toISOString().substr(0, 10),
          to:
            to > from
              ? to.toDate().toISOString().substr(0, 10)
              : from.toDate().toISOString().substr(0, 10),
          nbUnitLeft: this.nbUnitLeft,
        })
        .then((response) => {
          this.memberships = response.data;

          this.memberships = this.memberships
            .sort(function (a, b) {
              return new Date(b.dateBegin) - new Date(a.dateBegin);
            })
            .reverse();

          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.retrieveAll();
  },
};
</script>