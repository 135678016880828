<template>
  <v-select
    :items="membershipTypes"
    :label="
      $attrs['label'] == null
        ? $helpers.string.capitalize(
            $tc('membership-type.membership-type', 2),
            {
              required: $attrs['required'] != null,
            }
          )
        : $attrs['label']
    "
    v-bind="$attrs"
    v-on="$listeners"
    item-value="id"
    :item-text="getFullName"
    clearable
    chips
    multiple
    attach
    deletable-chips
    prepend-icon="mdi-tag-text-outline"
    :rules="$attrs['required'] != null ? $helpers.rule.notEmpty() : []"
  >
    <!-- <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        close
        @click:close="remove(data.item)"
      >
        <v-icon small class="mr-2">{{ getIconName(data.item) }}</v-icon>
        <span>{{ data.item.name }} </span>
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-icon small class="mr-2">{{ getIconName(data.item) }}</v-icon>
      <span>{{ data.item.name }} </span>
    </template> -->
  </v-select>
</template>

<script>
export default {
  name: "SelectableMembershipType",
  data() {
    return {
      membershipTypes: [],
    };
  },
  components: {},
  mounted() {
    this.retrieveMembershipTypes();
  },
  methods: {
    remove(item) {
      const index = this.$attrs["value"].findIndex(
        (element) => element.id === item.id
      );
      if (index >= 0) this.$attrs["value"].splice(index, 1);
    },
    getIconName(membershipType) {
      switch (membershipType.unit) {
        case "1":
          return "mdi-numeric-1-box";
        case null:
          return "mdi-infinity";
        default:
          return "mdi-numeric";
      }
    },

    getFullName(membershipType) {
      return membershipType.name;
    },
    retrieveMembershipTypes() {
      return this.$services.membershipType
        .getAll()
        .then((response) => {
          this.membershipTypes = response.data;
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N(
            "membershipTypes.error.retrieve"
          );
        });
    },
    getMembershipType(membershipTypeId) {
      return this.membershipTypes.find((m) => m.id === membershipTypeId);
    },
  },
};
</script>