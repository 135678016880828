<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-container>
        <v-row align="center" justify="start">
          <v-col class="text-h5"
            >{{
              $t("dashboard.welcome-username", {
                firstName: firstName,
              })
            }}
          </v-col>
          <v-col sm="4">
            <v-select
              v-model="period"
              :items="periods"
              solo
              hide-details
              @change="periodChanged"
              prepend-inner-icon="mdi-calendar"
              :loading="loading"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container v-if="planning.length > 0">
        <v-row
          v-for="(planning, index) in planning"
          :key="index"
          class="text-h6"
          :class="{ 'text-decoration-line-through grey--text': planning.canceled }"
        >
          <v-col md="1" class="shrink">
            <PlanningUnitsChip
              v-if="planning.lesson"
              :usedUnits="getUsedUnits(planning)"
              :totalUnits="getLessonUnit(planning)"
              small
            />
          </v-col>
          <v-col md="2">
            {{ $helpers.date.getDayMonthDisplay(planning.dateBegin) }}
          </v-col>
          <v-col md="2">
            {{ $helpers.date.getTimeStringFromDate(planning.dateBegin) }}
            <v-icon small>mdi-chevron-right</v-icon>
            {{ $helpers.date.getTimeStringFromDate(planning.dateEnd) }}
          </v-col>

          <v-col class="grow text-align-left">
            <!-- <v-badge :color="planningColor(planning)" dot>{{
              planningName(planning)
            }}</v-badge> -->
            <v-chip
              outlined
              label
              class="text-h6"
              :color="planningColor(planning)"
              >{{ planningName(planning) }}</v-chip
            >
          </v-col>
          <v-col>
            {{ planning.user.firstName }}
          </v-col>
          <v-col class="shrink text-align-right">
            <v-btn @click="editPlanning(planning)" icon small>
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col>
            {{ $helpers.string.capitalizeI18N("dashboard.no-planning") }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <PlanningEditionPopup
      ref="planningEdition"
      @onSaveClicked="saveClicked"
      @onCloseClicked="retrieveAll"
    />
  </v-card>
</template>

<script>
import PlanningEditionPopup from "@/components/Admin/Planning/PlanningEditionPopup";
import PlanningUnitsChip from "@/components/Admin/Unit/PlanningUnitsChip";

export default {
  name: "DashboardPlanning",
  components: {
    PlanningEditionPopup,
    PlanningUnitsChip,
  },
  data() {
    return {
      planning: [],
      user: null,
      periods: [
        {
          value: "last-30-days",
          text: this.$t("dashboard.last-30-days"),
        },
        {
          value: "last-7-days",
          text: this.$t("dashboard.last-7-days"),
        },
        {
          value: "next-7-days",
          text: this.$t("dashboard.next-7-days"),
        },
        {
          value: "next-30-days",
          text: this.$t("dashboard.next-30-days"),
        },
      ],
      period: "next-7-days",
      loading: true,
    };
  },
  computed: {
    firstName() {
      return this.user?.firstName;
    },
  },
  methods: {
    getLessonUnit(planning) {
      return Number(planning.lesson?.maximumUnit ?? 0);
    },
    getUsedUnits(planning) {
      return planning.planning_memberships.reduce(function (a, b) {
        return Number(a) + Number(b.unit ?? 0);
      }, 0);
    },
    saveClicked() {
      this.retrieveAll();
    },
    planningName(planning) {
      return planning.lesson?.name ?? planning.name;
    },
    planningColor(planning) {
      return planning.lesson?.color ?? planning.color;
    },
    editPlanning(planning) {
      this.$services.planning
        .get(planning.id)
        .then((response) => {
          this.$refs.planningEdition.open(response.data);
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    periodChanged() {
      this.retrieveAll();
    },
    async retrieveAll() {
      this.loading = true;

      this.user = await this.$store.dispatch("adminauth/fetchUser");

      const from = this.$moment(new Date());
      let to = this.$moment(new Date());

      switch (this.period) {
        case "next-7-days":
          to = to.add({ days: 7 });
          break;
        case "next-30-days":
          to = to.add({ days: 30 });
          break;
        case "last-7-days":
          to = to.add({ days: -7 });
          break;
        case "last-30-days":
          to = to.add({ days: -30 });
          break;
      }

      if (to > from) {
        from.set({ hour: 0, minute: 0, second: 0 });
        to.set({ hour: 23, minute: 59, second: 59 });
      } else {
        from.set({ hour: 23, minute: 59, second: 59 });
        to.set({ hour: 0, minute: 0, second: 0 });
      }

      this.$services.planning
        .getAll(
          to > from
            ? from.toDate().toISOString().substr(0, 10)
            : to.toDate().toISOString().substr(0, 10),
          to > from
            ? to.toDate().toISOString().substr(0, 10)
            : from.toDate().toISOString().substr(0, 10)
        )
        .then((response) => {
          this.planning = response.data;

          this.planning = this.planning
            .sort(function (a, b) {
              return new Date(b.dateBegin) - new Date(a.dateBegin);
            })
            .reverse();

          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.retrieveAll();
  },
};
</script>