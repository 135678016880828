<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-container>
          <v-row class="text-h6 primary--text">
            <v-icon color="primary" left>mdi-gift</v-icon> Invitez un ami et
            recevez un mois gratuit pour VOUS et votre filleul
          </v-row>
        </v-container>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container class="text-body-1">
          <v-row>
            <v-col md="6">
              Faire partie de la <span class="font-weight-medium primary--text">communauté monplanning</span>, c'est un cadeau que
              vous pouvez partager avec un ami, un collègue ou un collaborateur.
            </v-col></v-row
          >
          <v-row>
            <v-col md="6">
              En parrainant quelqu'un, vous et votre filleul recevez <span class="font-weight-bold primary--text">UN MOIS
              GRATUIT</span> (quel que soit le plan tarifaire choisi) lorsqu'il
              prolonge l'aventure monplanning pour la 1ère fois.
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              Entrez l'adresse email de votre ami et monplanning
              s'occupe de tout.
            </v-col></v-row
          >
          <v-row align="center">
            <v-col md="4">
              <v-form ref="form" @submit.prevent="sendInvitation()">
                <v-text-field
                  solo
                  label="Adresse email de votre ami"
                  v-model="email"
                  :rules="$helpers.rule.email()"
                  required
                />
                <v-btn color="primary" @click="sendInvitation()">
                  envoyer l'invitation
                  <v-icon right>mdi-send</v-icon>
                </v-btn>
              </v-form>
            </v-col></v-row
          >
          <v-row>
            <v-col md="6">
              Si vous préférez communiquer vous-même votre code de parrainage,
              votre filleul devra le mentionner lors de son inscription :
            </v-col></v-row
          >
          <v-row>
            <v-col md="4">
              <v-text-field
                solo
                readonly
                v-model="referralCode"
                ref="referralCode"
              >
                <template v-slot:append>
                  <v-btn color="primary" @click="copyReferralCode()">
                    copier
                    <v-icon right>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col></v-row
          >
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data() {
    return {
      organization: null,
      email: "",
    };
  },
  computed: {
    referralCode() {
      return this.organization?.referralCode;
    },
  },
  async mounted() {
    const organization = await this.$store.dispatch(
      "organization/fetchOrganization"
    );

    this.organization = organization;
  },
  methods: {
    copyReferralCode() {
      let referralCode = this.$refs.referralCode.$el.querySelector("input");
      referralCode.select();

      document.execCommand("copy");
    },
    async sendInvitation() {
      if (!this.$refs.form.validate()) return;

      await this.$services.organization.sendReferralInvitation(this.email);

      this.$helpers.snackbar.showSuccess("L'invitation a été envoyée à votre ami.");

      this.email = "";
    }
  },
};
</script>