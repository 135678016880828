import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{ref:"dialog",attrs:{"return-value":_vm.group,"persistent":"","max-width":"800px"},on:{"update:returnValue":function($event){_vm.group=$event},"update:return-value":function($event){_vm.group=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.group.id == null ? _vm.$helpers.string.capitalizeI18N("group.creation") : _vm.$helpers.string.capitalizeI18N("group.edition")))]),_c(VSwitch,{directives:[{name:"show",rawName:"v-show",value:(_vm.group.id),expression:"group.id"}],staticClass:"ml-4",attrs:{"label":_vm.$helpers.string.capitalizeI18N('active')},model:{value:(_vm.group.active),callback:function ($$v) {_vm.$set(_vm.group, "active", $$v)},expression:"group.active"}}),_c(VSpacer),_c('PopupCloseButton',{on:{"closeClick":_vm.close}})],1),_c(VCardText,[_c(VForm,{ref:"form"},[_c('FormGroupElement',{attrs:{"showMoreButton":false,"expanded":true}},[_c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c('ColorPicker',{ref:"colorPicker",model:{value:(_vm.group.color),callback:function ($$v) {_vm.$set(_vm.group, "color", $$v)},expression:"group.color"}}),_c(VTextField,{ref:"name",staticClass:"ml-4",attrs:{"label":_vm.$helpers.string.capitalizeI18N('name') + '*',"required":"","autofocus":"","rules":_vm.$helpers.rule.notEmpty()},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}})],1)],1)],1)],1),_c('small',[_vm._v("* "+_vm._s(_vm.$t("required-fields")))])],1),_c(VCardActions,[_c(VSpacer),_c('CardActionButton',{ref:"saveButton",attrs:{"type":"save"},on:{"click":_vm.saveAndClose}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }