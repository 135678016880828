import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"shrink"},[_c(VAvatar,{attrs:{"size":"96"}},[_c(VImg,{attrs:{"lazy-src":require("@/assets/blank-profile-picture.png"),"src":_vm.imageUrl,"contain":""}})],1)],1),_c(VCol,{staticClass:"text-h4 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.organizationName)+" "),(_vm.user && _vm.user.main)?_c(VBtn,{attrs:{"plain":"","to":_vm.$helpers.router.getAdminLink('my-organization')}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-eye")]),_vm._v(" voir les paramètres")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }