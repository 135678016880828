import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,_vm._g(_vm._b({attrs:{"items":_vm.membershipTypes,"label":_vm.$attrs['label'] == null
      ? _vm.$helpers.string.capitalize(
          _vm.$tc('membership-type.membership-type', 2),
          {
            required: _vm.$attrs['required'] != null,
          }
        )
      : _vm.$attrs['label'],"item-value":"id","item-text":_vm.getFullName,"clearable":"","chips":"","multiple":"","attach":"","deletable-chips":"","prepend-icon":"mdi-tag-text-outline","rules":_vm.$attrs['required'] != null ? _vm.$helpers.rule.notEmpty() : []}},'v-select',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }