<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="$attrs['value']"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <div :style="swatchStyle" v-on="on" />
    </template>
    <!-- <v-date-picker v-bind="$attrs" v-on="$listeners" no-title scrollable @input="$refs.menu.save($event)">
    </v-date-picker> -->
    <v-color-picker v-bind="$attrs" v-on="$listeners" hide-inputs @input="setColor($event)" />
  </v-menu>
</template>

<script>
export default {
  name: "ColorPicker",
  props: {},
  data() {
    return {
      menu: false,
      color: null
    };
  },
  computed: {
    swatchStyle() {
      //console.dir('color in swatch : ' + this.color)
      return {
        backgroundColor: this.color ?? '#000000',
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  methods: {
    setColor(c) {
      if (typeof c !== 'string')
        c = c?.hexa ?? '#000000';
      
      //console.log('set color : ' + c)

      this.color = c;
      this.$attrs['value'] = c;
      this.$emit("input", this.$attrs["value"]);
    }
  },
  mounted() {
    //console.dir(this.$attrs["value"])
    this.color = this.$attrs["value"] ?? '#000000';
  },
};
</script>

<!--
<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    v-mask="'!#XXXXXXXX'"
    :label="$helpers.string.capitalizeI18N('color')"
    class="ma-0 pa-0"
    solo
  >
    <template v-slot:append>
      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-bind="$attrs" v-on="$listeners" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>
-->
<!--
<script>
import { mask } from "vue-the-mask";

export default {
  name: "ColorPicker",
  directives: {
    mask,
  },
  computed: {
    swatchStyle() {
      return {
        backgroundColor: this.color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>
-->