<template>
  <v-row justify="center">
    <v-dialog ref="dialog" v-model="dialog" :return-value.sync="client" persistent max-width="800px"
      @keydown.esc="close()" :fullscreen="$helpers.display.mobileDialog" :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            client.id == null
            ? $helpers.string.capitalizeI18N("client.creation")
            : getFullName(client)
          }}</span>
          <v-switch v-show="client.id" v-model="client.active" class="ml-4"
            :label="$helpers.string.capitalizeI18N('active')"></v-switch>
          <v-spacer />
          <!-- <span v-if="client.login" class="mr-4 font-weight-medium">{{
            client.login
          }}</span> -->

          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row>
                  <v-text-field :label="$helpers.string.capitalizeI18N('first-name')" v-model="client.firstName"
                    prepend-icon="mdi-account" autofocus></v-text-field>
                  <v-text-field :label="$helpers.string.capitalizeI18N('last-name')" v-model="client.lastName"
                    prepend-icon="mdi-account" :class="$helpers.display.spacerDesktopClass"></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field :label="$helpers.string.capitalizeI18N('company-name')" v-model="client.companyName"
                    prepend-icon="mdi-account"></v-text-field>
                  <v-text-field :label="$helpers.string.capitalizeI18N('vat-number')" v-model="client.vatNumber"
                    prepend-icon="mdi-card-text" :class="$helpers.display.spacerDesktopClass"></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field :label="$helpers.string.capitalizeI18N('email')" v-model="client.email" type="email"
                    :rules="$helpers.rule.email()" prepend-icon="mdi-email"></v-text-field>
                  <v-text-field :label="$helpers.string.capitalizeI18N('phone-number')" v-model="client.phoneNumber"
                    type="phone" prepend-icon="mdi-phone" :class="$helpers.display.spacerDesktopClass"></v-text-field>
                </v-row>
                <v-expand-transition>
                  <v-row v-if="!client.password && client.email">
                    <v-checkbox v-model="client.send_email_welcome_client" label="Envoyer un email de bienvenue"
                      :disabled="billPlan === 'essential'" class="ml-8" />
                  </v-row>
                </v-expand-transition>
              </v-container>
            </FormGroupElement>

            <FormGroupElement :title="$helpers.string.capitalizeI18N('other-information')"
              :expanded="showOtherInformation" color="blue">
              <v-container>
                <v-row>
                  <v-col>
                    <SelectableGroups v-model="client.groups" />
                  </v-col>
                </v-row>
                <v-row>
                  <DatePicker v-model="client.birthdate" :label="$helpers.string.capitalizeI18N('birthdate')" />
                  <v-select v-model="client.gender" :items="['m', 'f', 'o']"
                    :label="$helpers.string.capitalizeI18N('gender')" prepend-icon="mdi-gender-male-female"
                    :class="$helpers.display.spacerDesktopClass">
                    <template v-slot:selection="data">
                      {{ getGenderLabel(data.item) }}
                    </template>
                    <template v-slot:item="data">
                      {{ getGenderLabel(data.item) }}
                    </template>
                  </v-select>
                  <v-select v-model="client.language" :items="['fr', 'nl', 'en']"
                    :label="$helpers.string.capitalizeI18N('language')" prepend-icon="mdi-comment-outline"
                    :class="$helpers.display.spacerDesktopClass">
                    <template v-slot:selection="data">
                      {{ getLanguageLabel(data.item) }}
                    </template>
                    <template v-slot:item="data">
                      {{ getLanguageLabel(data.item) }}
                    </template></v-select>
                </v-row>
                <v-row>
                  <v-textarea v-model="client.comment" :label="$helpers.string.capitalizeI18N('comment')"
                    outlined></v-textarea>
                </v-row>
              </v-container>
            </FormGroupElement>

            <FormGroupAddressEdition color="success" v-model="client.address" />

            <v-container v-if="!client.password && client.email">
              <v-row align="center"><v-checkbox v-model="client.send_email_welcome_client"
                  label="Envoyer un email de bienvenue" />
                  
                <!-- <span v-else>
                  Ce client est déjà inscrit et a déjà un mot de passe. Il n'est donc pas possible d'envoyer un email de bienvenue.
                </span> -->
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton @click="saveAndClose" type="save" ref="saveButton" />
          <!-- <CardActionButton @click="saveAndNew" i18ntext="saveandnew" /> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import SelectableGroups from "../Group/SelectableGroups";
import DatePicker from "@/components/Admin/DatePicker";
import FormGroupAddressEdition from "../Address/FormGroupAddressEdition";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";

import { mask } from "vue-the-mask";
import Address from "@/models/address";

export default {
  name: "ClientEditionPopup",
  data() {
    return {
      dialog: false,
      client: {},
      organization: null,
    };
  },
  components: {
    CardActionButton,
    SelectableGroups,
    DatePicker,
    FormGroupAddressEdition,
    FormGroupElement,
    PopupCloseButton,
  },
  directives: {
    mask,
  },
  computed: {
    billPlan() {
      return this.organization?.billPlan;
    },
    showOtherInformation() {
      return (
        this.client.birthdate != null ||
        this.client.gender != null ||
        this.client.language != null ||
        this.client.comment != null ||
        Object.keys(this.client.groups ?? []).length > 0
      );
    },
  },
  methods: {
    getFullName(client) {
      const fullName = (
        (client.firstName ?? "") +
        " " +
        (client.lastName ?? "")
      ).trim();

      return client.companyName
        ? fullName
          ? `${fullName} (${client.companyName})`
          : client.companyName
        : fullName;
    },
    getGenderLabel(gender) {
      switch (gender) {
        case "o":
          return this.$helpers.string.capitalizeI18N("gender-o");
        case "m":
          return this.$helpers.string.capitalizeI18N("gender-m");
        case "f":
          return this.$helpers.string.capitalizeI18N("gender-f");
        default:
          return gender;
      }
    },
    getLanguageLabel(language) {
      switch (language) {
        case "fr":
          return this.$helpers.string.capitalizeI18N("language-fr");
        case "en":
          return this.$helpers.string.capitalizeI18N("language-en");
        case "nl":
          return this.$helpers.string.capitalizeI18N("language-nl");
        default:
          return language;
      }
    },
    async saveClient() {
      try {
        const response = await this.$services.client.save(this.client);

        this.client = response.data;

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadClient() {
      if (!this.client.address) this.client.address = new Address();
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        if (!this.$refs.form.validate()) return;

        await this.saveClient();

        this.$helpers.snackbar.showSuccessI18N("client.save.success");

        this.$emit("onSaveClicked", this.client);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.saveClient();
      this.$emit("onSaveClicked", this.client);
      this.client = {};
      this.loadClient();
      this.$refs.firstName.focus();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(client) {
      this.client = client;
      this.loadClient();
      this.dialog = true;
    },
  },
  async mounted() {
    this.organization = await this.$store.dispatch(
      "organization/fetchOrganization"
    );
  },
};
</script>