<template>
  <v-sheet elevation="0">
    <v-autocomplete
      :items="groups"
      v-bind="$attrs"
      v-on="$listeners"
      item-value="id"
      item-text="name"
      return-object
      :label="$helpers.string.capitalizeI18N('groups')"
      ref="groupsSelect"
      :search-input.sync="groupName"
      chips
      prepend-icon="mdi-account-group-outline"

      multiple
    >
      <template v-slot:append-outer>
        <v-btn icon @click="addClicked" tabindex="-1">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>
          <span v-html="$t('group.info-tooltip')"></span>
        </v-tooltip>
      </template>

      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          :color="data.item.color"
          close
          outlined
          @click="data.select"
          @click:close="remove(data.item)"
        >
          {{ data.item.name }}
          <v-btn
            class="ml-2"
            :color="data.item.color"
            icon
            x-small
            @click="editClicked(data.item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-chip>
      </template>

      <!-- <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar :color="data.item.color"></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>-->

      <!-- <template v-slot:no-data>
        <v-list-item v-if="groupName">
          <v-list-item-content>
            <v-list-item-title>
              <i18n path="no-matching-create" tag="p">
                <template v-slot:search>
                  <span class="font-weight-bold">{{ groupName }}</span>
                </template>
                <template v-slot:enterKey>
                  <kbd>enter</kbd>
                </template>
              </i18n>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>-->
    </v-autocomplete>
    <GroupEditionPopup ref="groupEdition" @onSaveClicked="saveClicked" />
  </v-sheet>
</template>

<script>
import GroupEditionPopup from "./GroupEditionPopup.vue";

export default {
  name: "SelectableGroups",
  data() {
    return {
      groupName: "",
      groups: [],
    };
  },
  components: {
    GroupEditionPopup,
  },
  mounted() {
    this.retrieveGroups();
  },
  methods: {
    remove(item) {
      const index = this.$attrs["value"].findIndex(
        (element) => element.id === item.id
      );
      if (index >= 0) this.$attrs["value"].splice(index, 1);
    },
    // isEditDisabled() {
    //   return this.$attrs["value"] == null;
    // },
    enterPressed() {
      if (
        this.$refs.groupsSelect.computedItems.length == 0 &&
        this.groupName != null
      ) {
        this.saveClicked({ name: this.groupName }).then(() => {
          this.$refs.groupsSelect.blur();
        });
      }
    },
    // clearClicked() {
    //   this.$attrs["value"] = null;
    //   this.$emit("input", this.$attrs["value"]);
    //   this.$refs.groupsSelect.focus();
    // },
    addClicked() {
      this.$refs.groupEdition.open({ color: "#F1AA67", active: true });
    },
    editClicked(group) {
      this.$services.group
        .get(group.id)
        .then((response) => {
          this.$refs.groupEdition.open(response.data);
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("groups.error.notfound");
        });
    },
    retrieveGroups() {
      return this.$services.group
        .getAll()
        .then((response) => {
          this.groups = response.data;
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("groups.error.retrieve");
        });
    },
    saveClicked(value) {
      console.dir(this.$attrs["value"]);

      if (this.$attrs["value"] === undefined)
        this.$attrs["value"] = new Array();

      this.retrieveGroups().then(() => {
        console.dir(value);
      });

      // this.$attrs["value"] = this.$attrs["value"].push({
      //   selected: true,
      //   item: response.data.id,
      // });
      // this.$emit("input", this.$attrs["value"]);
      // this.$emit("change", this.$attrs["value"]);
    },
  },
};
</script>