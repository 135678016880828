<template>
  <v-row justify="center">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="planningPick"
      persistent
      max-width="800px"
      @keydown.esc="close()"
      :fullscreen="$helpers.display.mobileDialog"
      :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $helpers.string.capitalizeI18N("planning.pick.title")
          }}</span>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row>
                  <v-col>
                    <SelectableLesson
                      v-model="planningPick.lessonId"
                      ref="selectableLesson"
                      @input="lessonChanged($event)"
                      required
                      readonly
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <SelectableUser
                      v-model="planningPick.userId"
                      ref="selectableUser"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
            <FormGroupElement
              :title="$helpers.string.capitalizeI18N('planning.planning')"
              :expanded="true"
              :showMoreButton="false"
            >
              <v-container>
                <v-row v-if="showTimes">
                  <v-col>
                    <TimePicker
                      v-model="planningPick.hourBegin"
                      v-bind:label="
                        $helpers.string.capitalizeI18N('hour-begin', {
                          required: true,
                        })
                      "
                      required
                    />
                  </v-col>
                  <v-col>
                    <TimePicker
                      v-model="planningPick.hourEnd"
                      v-bind:label="
                        $helpers.string.capitalizeI18N('hour-end', {
                          required: true,
                        })
                      "
                      v-bind:error="hourEndError"
                      required
                  /></v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-date-picker
                      v-model="planningPick.dates"
                      full-width
                      first-day-of-week="1"
                      multiple
                    ></v-date-picker>
                  </v-col>
                </v-row>

                <v-row
                  v-if="dateTimeValid && planningPick.dates.length > 0"
                  align="center"
                  justify="center"
                >
                  <v-alert type="success" prominent outlined border="left">
                    {{ planningPick.dates.length }} dates de
                    <span v-if="planningPick.hourBegin">{{
                      $helpers.date.getTimeStringFromTimeString(planningPick.hourBegin)
                    }}</span>

                    à
                    <span v-if="planningPick.hourEnd">{{
                      $helpers.date.getTimeStringFromTimeString(planningPick.hourEnd)
                    }}</span>
                  </v-alert>
                </v-row>
              </v-container>
            </FormGroupElement>
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton
            @click="saveAndClose"
            type="save"
            ref="saveButton"
          />
          <!-- <CardActionButton @click="saveAndNew" i18ntext="saveandnew" /> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import TimePicker from "@/components/Admin/TimePicker";
import SelectableLesson from "@/components/Admin/Lesson/SelectableLesson";
import SelectableUser from "@/components/Admin/User/SelectableUser";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";

export default {
  name: "PickPlanningPopup",
  components: {
    CardActionButton,
    TimePicker,
    SelectableLesson,
    SelectableUser,
    FormGroupElement,
    PopupCloseButton
  },
  data() {
    return {
      dialog: false,
      planningPick: {
        dates: [],
      },
      valid: true,
      hourEndError: null,
      showTimes: false,
    };
  },
  computed: {
    dateTimeValid() {
      return this.planningPick.hourBegin && this.planningPick.hourEnd;
    },
  },
  methods: {
    getWeekdayName(weekday) {
      return this.$helpers.string.capitalize(
        this.$helpers.date.getWeekdayName(weekday)
      );
    },
    async saveplanningPick() {
      try {
        const payload = {
          lessonId: this.planningPick.lessonId,
          hourBegin: this.planningPick.hourBegin,
          hourEnd: this.planningPick.hourEnd,
          dates: this.planningPick.dates,
          userId: this.planningPick.userId,
        };

        await this.$services.planning.pick(payload);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadplanningPick() {
      this.showTimes =
        !this.planningPick.hourBegin || !this.planningPick.hourEnd;
    },
    retrieveLessons() {
      this.$refs.selectableLesson?.retrieveLessons();
    },
    lessonChanged(lessonId) {
      const lesson = this.$refs.selectableLesson.lessons.find(
        (element) => element.id === lessonId
      );

      if (lesson && lesson.userId) {
        this.planningPick.userId = lesson.userId;
      }
    },
    validateForm() {
      if (!this.$refs.form.validate()) return false;
// console.log("validateForm 1")
//       const dateBegin = this.$helpers.date.addTimeStringToDate(
//         new Date(2000, 1, 1),
//         this.planningPick.hourBegin
//       );
//       console.log("validateForm 2", dateBegin)
//       const dateEnd = this.$helpers.date.addTimeStringToDate(
//         new Date(2000, 1, 1),
//         this.planningPick.hourEnd
//       );
//       console.log("validateForm 3", dateEnd)
//       if (dateBegin >= dateEnd) {
//         this.hourEndError = this.$helpers.string.capitalizeI18N(
//           "hour-begin-greater-than-hour-end"
//         );
//         return false;
//       }
// console.log("ok !")
      return true;
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        console.log("this.planningPick", this.planningPick);

        if (!this.validateForm()) return;

        await this.saveplanningPick();

        this.$helpers.snackbar.showSuccessI18N("planning.pick.success");

        this.$emit("onSaveClicked", this.planningPick);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.saveplanningPick();
      this.$emit("onSaveClicked", this.planningPick);
      this.planningPick = {};
      this.loadplanningPick();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(planningPick) {
      this.planningPick = planningPick;
      this.loadplanningPick();
      this.dialog = true;
    },
  },
};
</script>