<template>
  <v-row justify="center">
    <v-dialog ref="dialog" v-model="dialog" :return-value.sync="planningGeneration" persistent max-width="800px"
      @keydown.esc="close()" :fullscreen="$helpers.display.mobileDialog" :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $helpers.string.capitalizeI18N("planning.generate.title")
          }}</span>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row>
                  <v-col>
                    <SelectableLesson v-model="planningGeneration.lessonId" ref="selectableLesson"
                      @input="lessonChanged($event)" required readonly />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <SelectableUser v-model="planningGeneration.userId" ref="selectableUser" />
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
            <FormGroupElement :title="$helpers.string.capitalizeI18N('planning.planning')" :expanded="true"
              :showMoreButton="false">
              <v-container>
                <v-row>
                  <v-col>
                    <DatePicker v-model="planningGeneration.dateBegin" v-bind:label="$helpers.string.capitalizeI18N('date-begin', {
                      required: true,
                    })
                      " required />
                    <!-- <v-date-picker
                      v-model="planningGeneration.dateBegin"
                      first-day-of-week="1"
                    ></v-date-picker> -->
                  </v-col>
                  <v-col>
                    <DatePicker v-model="planningGeneration.dateEnd" v-bind:label="$helpers.string.capitalizeI18N('date-end', {
                      required: true,
                    })
                      " required />
                  </v-col>
                </v-row>

                <v-row v-if="showTimes">
                  <v-col>
                    <TimePicker v-model="planningGeneration.hourBegin" v-bind:label="$helpers.string.capitalizeI18N('hour-begin', {
                      required: true,
                    })
                      " required />
                  </v-col>
                  <v-col>
                    <TimePicker v-model="planningGeneration.hourEnd" v-bind:label="$helpers.string.capitalizeI18N('hour-end', {
                      required: true,
                    })
                      " v-bind:error="hourEndError" required />
                  </v-col>
                </v-row>

                <v-row v-if="dateTimeValid && planningGeneration.days.length > 0" align="center" justify="center">
                  <v-alert type="success" prominent outlined border="left">
                    Ceci ajoutera 
                    <!-- {{ getNbWeekday() }} -->

                    <!-- --{{ planningGeneration.lesson }}-- -->
                    <div v-show="planningGeneration.lesson.monday">
                      {{ getNbWeekday(1) }} {{ $helpers.date.getWeekdayName(1) }}{{ getNbWeekday(1) > 1 ? "s" : "" }} 
                    </div>
                    <div v-show="planningGeneration.lesson.tuesday">
                      {{ getNbWeekday(2) }} {{ $helpers.date.getWeekdayName(2) }}{{ getNbWeekday(2) > 1 ? "s" : "" }} 
                    </div>
                    <div v-show="planningGeneration.lesson.wednesday">
                      {{ getNbWeekday(3) }} {{ $helpers.date.getWeekdayName(3) }}{{ getNbWeekday(3) > 1 ? "s" : "" }} 
                    </div>
                    <div v-show="planningGeneration.lesson.thursday">
                      {{ getNbWeekday(4) }} {{ $helpers.date.getWeekdayName(4) }}{{ getNbWeekday(4) > 1 ? "s" : "" }} 
                    </div>
                    <div v-show="planningGeneration.lesson.friday">
                      {{ getNbWeekday(5) }} {{ $helpers.date.getWeekdayName(5) }}{{ getNbWeekday(5) > 1 ? "s" : "" }} 
                    </div>
                    <div v-show="planningGeneration.lesson.saturday">
                      {{ getNbWeekday(6) }} {{ $helpers.date.getWeekdayName(6) }}{{ getNbWeekday(6) > 1 ? "s" : "" }} 
                    </div>
                    <div v-show="planningGeneration.lesson.sunday">
                      {{ getNbWeekday(7) }} {{ $helpers.date.getWeekdayName(7) }}{{ getNbWeekday(7) > 1 ? "s" : "" }} 
                    </div>
                    

                    <!-- {{ $helpers.date.getWeekdayName(planningGeneration.days[0]) }}-->

                    de
                    <span v-if="planningGeneration.hourBegin">{{
                      $helpers.date.getTimeStringFromTimeString(planningGeneration.hourBegin)
                    }}</span>

                    à
                    <span v-if="planningGeneration.hourEnd">{{
                      $helpers.date.getTimeStringFromTimeString(planningGeneration.hourEnd)
                    }}</span>
                    dans votre agenda.
                  </v-alert>
                </v-row>

                <!-- <v-row>
                  <v-checkbox
                    v-model="planningGeneration.days"
                    value="1"
                    :label="$helpers.string.capitalizeI18N('monday')"
                    :rules="$helpers.rule.atLeastOne('')"
                  />

                  <v-checkbox
                    v-model="planningGeneration.days"
                    value="2"
                    :label="$helpers.string.capitalizeI18N('tuesday')"
                    :rules="$helpers.rule.atLeastOne('')"
                    class="ml-6"
                  />
                  <v-checkbox
                    v-model="planningGeneration.days"
                    value="3"
                    :label="$helpers.string.capitalizeI18N('wednesday')"
                    :rules="$helpers.rule.atLeastOne('')"
                    class="ml-6"
                  />
                  <v-checkbox
                    v-model="planningGeneration.days"
                    value="4"
                    :label="$helpers.string.capitalizeI18N('thursday')"
                    :rules="$helpers.rule.atLeastOne('')"
                    class="ml-6"
                  />
                </v-row>
                <v-row>
                  <v-checkbox
                    v-model="planningGeneration.days"
                    value="5"
                    :label="$helpers.string.capitalizeI18N('friday')"
                    :rules="$helpers.rule.atLeastOne('')"
                  />
                  <v-checkbox
                    v-model="planningGeneration.days"
                    value="6"
                    :label="$helpers.string.capitalizeI18N('saturday')"
                    :rules="$helpers.rule.atLeastOne('')"
                    class="ml-6"
                  />
                  <v-checkbox
                    v-model="planningGeneration.days"
                    value="0"
                    :label="$helpers.string.capitalizeI18N('sunday')"
                    :rules="$helpers.rule.atLeastOne('')"
                    class="ml-6"
                  />
                </v-row> -->
              </v-container>
            </FormGroupElement>
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton @click="saveAndClose" type="save" ref="saveButton" />
          <!-- <CardActionButton @click="saveAndNew" i18ntext="saveandnew" /> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
//import ColorPicker from "@/components/Admin/ColorPicker";
import TimePicker from "@/components/Admin/TimePicker";
import DatePicker from "@/components/Admin/DatePicker";
import SelectableLesson from "@/components/Admin/Lesson/SelectableLesson";
import SelectableUser from "@/components/Admin/User/SelectableUser";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";

export default {
  name: "GeneratePlanningPopup",
  components: {
    CardActionButton,
    //ColorPicker,
    TimePicker,
    DatePicker,
    SelectableLesson,
    SelectableUser,
    FormGroupElement,
    PopupCloseButton,
  },
  data() {
    return {
      dialog: false,
      planningGeneration: {
        days: [],
        lesson: null,
        lessonId: null,
      },
      lessonId: null,
      valid: true,
      name: "",
      hourEndError: null,
      showTimes: false,
    };
  },
  computed: {
    dateTimeValid() {
      return (
        this.planningGeneration.dateBegin &&
        this.planningGeneration.dateEnd &&
        this.planningGeneration.hourBegin &&
        this.planningGeneration.hourEnd
      );
    },
  },
  methods: {
    getNbWeekday(weekday) {
      if (
        !this.planningGeneration.dateBegin ||
        !this.planningGeneration.dateEnd ||
        this.planningGeneration.days.length == 0
      )
        return 0;

      return this.getWeekdaysBetween(
        this.$moment(this.planningGeneration.dateBegin),
        this.$moment(this.planningGeneration.dateEnd),
        // parseInt(this.planningGeneration.days[0])
        weekday,
      );
    },
    getWeekdaysBetween(d1, d2, isoWeekday) {
      // ensure we have valid moment instances
      d1 = this.$moment(d1);
      d2 = this.$moment(d2);
      // figure out how many days to advance to get to the next
      // specified weekday (might be 0 if d1 is already the
      // specified weekday).
      var daysToAdd = (7 + isoWeekday - d1.isoWeekday()) % 7;
      var nextTuesday = d1.clone().add(daysToAdd, "days");
      // if we are already passed the end date, there must not
      // be any of that day in the given period.
      if (nextTuesday.isAfter(d2)) {
        return 0;
      }
      // otherwise, just return the whole number of weeks
      // difference plus one for the day we already advanced to
      var weeksBetween = d2.diff(nextTuesday, "weeks");
      return weeksBetween + 1;
    },
    getWeekdayName(weekday) {
      return this.$helpers.string.capitalize(
        this.$helpers.date.getWeekdayName(weekday)
      );
    },
    async savePlanningGeneration() {
      try {
        const payload = {
          lessonId: this.planningGeneration.lessonId,
          //color: this.planningGeneration.color,
          dateBegin: this.planningGeneration.dateBegin,
          dateEnd: this.planningGeneration.dateEnd,
          hourBegin: this.planningGeneration.hourBegin,
          hourEnd: this.planningGeneration.hourEnd,
          days: this.planningGeneration.days,
          userId: this.planningGeneration.userId,
        };

        await this.$services.planning.generate(payload);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async loadPlanningGeneration() {
      // if (this.$refs.colorPicker)
      //   this.$refs.colorPicker.color = this.planningGeneration.color;
      // if (this.planningGeneration.days) {
      //   this.days.splice(0, this.days);
      //   this.days.push.apply(this.days, this.planningGeneration.days);
      // }

      const days = [];

      if (this.planningGeneration.lesson.monday) days.push(1);
      if (this.planningGeneration.lesson.tuesday) days.push(2);
      if (this.planningGeneration.lesson.wednesday) days.push(3);
      if (this.planningGeneration.lesson.thursday) days.push(4);
      if (this.planningGeneration.lesson.friday) days.push(5);
      if (this.planningGeneration.lesson.saturday) days.push(6);
      if (this.planningGeneration.lesson.sunday) days.push(7);

      this.planningGeneration.days = days;

      this.showTimes =
        !this.planningGeneration.hourBegin || !this.planningGeneration.hourEnd;
    },
    retrieveLessons() {
      this.$refs.selectableLesson?.retrieveLessons();
    },
    lessonChanged(lessonId) {
      console.dir("set color for " + lessonId);
      const lesson = this.$refs.selectableLesson.lessons.find(
        (element) => element.id === lessonId
      );

      if (lesson && lesson.userId) {
        this.planningGeneration.lesson = lesson;
        //this.$refs.colorPicker.color = lesson.color;
        //this.planningGeneration.color = lesson.color;
        this.planningGeneration.userId = lesson.userId;
      }
    },
    validateForm() {
      if (!this.$refs.form.validate()) return false;

      const dateBegin = this.$helpers.date.addTimeStringToDate(
        new Date(2000, 1, 1),
        this.planningGeneration.hourBegin
      );
      const dateEnd = this.$helpers.date.addTimeStringToDate(
        new Date(2000, 1, 1),
        this.planningGeneration.hourEnd
      );
      if (dateBegin >= dateEnd) {
        this.hourEndError = this.$helpers.string.capitalizeI18N(
          "hour-begin-greater-than-hour-end"
        );
        return false;
      }

      return true;
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        console.dir(this.planningGeneration);
        if (!this.validateForm()) return;

        await this.savePlanningGeneration();

        this.$helpers.snackbar.showSuccessI18N("planning.generate.success");

        this.$emit("onSaveClicked", this.planningGeneration);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.savePlanningGeneration();
      this.$emit("onSaveClicked", this.planningGeneration);
      this.planningGeneration = {};
      this.loadPlanningGeneration();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(planningGeneration) {
      this.planningGeneration = planningGeneration;
      this.loadPlanningGeneration();
      this.dialog = true;
    },
  },
};
</script>