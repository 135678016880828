<template>
  <v-row justify="center">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="group"
      persistent
      max-width="800px"
      @keydown.esc="close()"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            group.id == null
              ? $helpers.string.capitalizeI18N("group.creation")
              : $helpers.string.capitalizeI18N("group.edition")
          }}</span>
          <v-switch
            class="ml-4"
            v-model="group.active"
            v-show="group.id"
            :label="$helpers.string.capitalizeI18N('active')"
          ></v-switch>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row align="center">
                  <ColorPicker v-model="group.color" ref="colorPicker" />
                  <v-text-field
                    :label="$helpers.string.capitalizeI18N('name') + '*'"
                    ref="name"
                    v-model="group.name"
                    required
                    autofocus
                    class="ml-4"
                    :rules="$helpers.rule.notEmpty()"
                  ></v-text-field>
                </v-row>
              </v-container>
            </FormGroupElement>
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton
            @click="saveAndClose"
            type="save"
            ref="saveButton"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import ColorPicker from "@/components/Admin/ColorPicker";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";

export default {
  name: "GroupEditionPopup",
  components: {
    CardActionButton,
    ColorPicker,
    FormGroupElement,
    PopupCloseButton,
  },
  data: () => ({
    dialog: false,
    group: {},
  }),
  methods: {
    async saveGroup() {
      try {
        const response = await this.$services.group.save(this.group);

        this.group = response;

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadGroup() {
      if (this.$refs.colorPicker)
        this.$refs.colorPicker.color = this.group.color;
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        if (!this.$refs.form.validate()) return;

        await this.saveGroup();

        this.$helpers.snackbar.showSuccessI18N("group.save.success");

        this.$emit("onSaveClicked", this.group);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.$emit("onSaveClicked", this.group);
      this.group = {};
      this.$refs.name.focus();
    },
    close() {
      this.dialog = false;
    },
    open(group) {
      this.group = group;

      this.loadGroup();

      this.dialog = true;
    },
  },
};
</script>