import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{attrs:{"elevation":"0"}},[_c(VAutocomplete,_vm._g(_vm._b({ref:"groupsSelect",attrs:{"items":_vm.groups,"item-value":"id","item-text":"name","return-object":"","label":_vm.$helpers.string.capitalizeI18N('groups'),"search-input":_vm.groupName,"chips":"","prepend-icon":"mdi-account-group-outline","multiple":""},on:{"update:searchInput":function($event){_vm.groupName=$event},"update:search-input":function($event){_vm.groupName=$event}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VBtn,{attrs:{"icon":"","tabindex":"-1"},on:{"click":_vm.addClicked}},[_c(VIcon,[_vm._v("mdi-plus")])],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('group.info-tooltip'))}})])]},proxy:true},{key:"selection",fn:function(data){return [_c(VChip,_vm._b({attrs:{"input-value":data.selected,"color":data.item.color,"close":"","outlined":""},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" "),_c(VBtn,{staticClass:"ml-2",attrs:{"color":data.item.color,"icon":"","x-small":""},on:{"click":function($event){return _vm.editClicked(data.item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)]}}])},'v-autocomplete',_vm.$attrs,false),_vm.$listeners)),_c('GroupEditionPopup',{ref:"groupEdition",on:{"onSaveClicked":_vm.saveClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }