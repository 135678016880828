<template>
  <div class="mb-8" v-if="showWelcome">
    <div>
      <v-alert type="success" prominent dismissible v-model="alert">
        Bienvenue et merci d'avoir choisi monplanning !<br />
        Voici 5 étapes pour débuter votre expérience sur monplanning.
        <!-- Commencez par encoder un de
            <v-btn
              text
              type="primary"
              to="/admin/lessons"
              class="text-decoration-underline"
              >vos cours</v-btn
            >
            <br />
            
            Vous voulez remplir votre planning ?
            <v-btn
              text
              type="primary"
              to="/admin/lessons"
              class="text-decoration-underline"
              >Générez le planning</v-btn
            >
            ou
            <v-btn
              text
              type="primary"
              to="/admin/lessons"
              class="text-decoration-underline"
              >Choisissez les dates</v-btn
            > -->
      </v-alert>
    </div>
    <div>
      <v-alert :color="nbLessons == 0 ? 'primary' : 'success'" outlined>
        <v-chip :color="nbLessons == 0 ? 'primary' : 'success'" class="pa-4 mr-4">1</v-chip>
        Ajouter votre premier
        <v-btn text :color="nbLessons == 0 ? 'primary' : 'success'" @click="addLesson()"
          class="text-decoration-underline">cours</v-btn>
        <v-icon right color="success" v-show="nbLessons > 0">mdi-check</v-icon>
      </v-alert>
    </div>
    <div>
      <v-alert :color="nbPlanning == 0 ? 'primary' : 'success'" outlined>
        <v-chip :color="nbPlanning == 0 ? 'primary' : 'success'" class="pa-4 mr-4">2</v-chip>
        Remplir votre agenda en
        <v-btn text :color="nbPlanning == 0 ? 'primary' : 'success'" @click="planningToCalendar()"
          class="text-decoration-underline">ajoutant votre planning</v-btn>
        <v-icon right color="success" v-show="nbPlanning > 0">mdi-check</v-icon>
      </v-alert>
    </div>
    <div>
      <v-alert :color="nbMembershipTypes == 0 ? 'primary' : 'success'" outlined>
        <v-chip :color="nbMembershipTypes == 0 ? 'primary' : 'success'" class="pa-4 mr-4">3</v-chip>
        Ajouter votre premier
        <v-btn text :color="nbMembershipTypes == 0 ? 'primary' : 'success'" @click="addMembershipType()"
          class="text-decoration-underline">type d'abonnement / adhésion</v-btn>
        <v-icon right color="success" v-show="nbMembershipTypes > 0">mdi-check</v-icon>
      </v-alert>
    </div>
    <div>
      <v-alert :color="nbClients == 0 ? 'primary' : 'success'" outlined>
        <v-chip :color="nbClients == 0 ? 'primary' : 'success'" class="pa-4 mr-4">4</v-chip>
        Encoder votre premier
        <v-btn text :color="nbClients == 0 ? 'primary' : 'success'" @click="addClient()"
          class="text-decoration-underline">client</v-btn>
        <v-icon right color="success" v-show="nbClients > 0">mdi-check</v-icon>
      </v-alert>
    </div>
    <div>
      <v-alert :color="nbMemberships == 0 ? 'primary' : 'success'" outlined>
        <v-chip :color="nbMemberships == 0 ? 'primary' : 'success'" class="pa-4 mr-4">5</v-chip>
        Assigner votre premier abonnement à votre premier client
        <v-btn text :color="nbMemberships == 0 ? 'primary' : 'success'" @click="addMembership()"
          class="text-decoration-underline">ajouter un abonnement</v-btn>
        <v-icon right color="success" v-show="nbMemberships > 0">mdi-check</v-icon>
      </v-alert>
    </div>
    <div>
      <v-alert colored-border border="bottom" type="success" v-show="showFinished">
        <!-- v-model="alert"
        dismissible -->
        Félicitations ! Vous avez maintenant tout en main pour profiter
        pleinement de monplanning et découvrir ses nombreuses fonctionnalités.
      </v-alert>
    </div>
    <div>
      <LessonEditionPopup ref="lessonEdition" @onSaveClicked="saveLessonClicked" @onCloseClicked="retrieveAll"
        :showLimitMemberships="false" />
      <GeneratePlanningPopup ref="generatePlanningPopup" @onSaveClicked="savePlanningGenerationClicked" />
      <PickPlanningPopup ref="pickPlanningPopup" @onSaveClicked="savePlanningPickClicked" />
      <MembershipTypeEditionPopup ref="membershipTypeEdition" @onSaveClicked="saveMembershipTypeClicked"
        @onCloseClicked="retrieveAll" />
      <ClientEditionPopup ref="clientEdition" @onSaveClicked="saveClientClicked" @onCloseClicked="retrieveAll" />
      <MembershipEditionPopup ref="membershipEdition" @onSaveClicked="saveMembershipClicked"
        @onCloseClicked="retrieveAll" />
    </div>
  </div>
</template>

<script>
import LessonEditionPopup from "@/components/Admin/Lesson/LessonEditionPopup";
import GeneratePlanningPopup from "@/components/Admin/Planning/GeneratePlanningPopup";
import PickPlanningPopup from "@/components/Admin/Planning/PickPlanningPopup";
import MembershipTypeEditionPopup from "@/components/Admin/MembershipType/MembershipTypeEditionPopup";
import ClientEditionPopup from "@/components/Admin/Client/ClientEditionPopup";
import MembershipEditionPopup from "@/components/Admin/Membership/MembershipEditionPopup";

export default {
  components: {
    LessonEditionPopup,
    GeneratePlanningPopup,
    PickPlanningPopup,
    MembershipTypeEditionPopup,
    ClientEditionPopup,
    MembershipEditionPopup,
  },
  data() {
    return {
      overview: {},
      showWelcome: false,
      showFinished: false,
    };
  },
  computed: {
    alert: {
      get: function () {
        return !(localStorage.getItem("hide-welcome") ?? false);
      },
      set: function (newValue) {
        localStorage.setItem("hide-welcome", !newValue);

        this.showWelcome = newValue;
        console.dir(this.showWelcome);
      },
    },
    nbLessons() {
      //   return 0;
      return this.overview?.lessons?.nb ?? 0;
    },
    lastLesson() {
      return this.overview?.lessons?.last;
    },
    nbPlanning() {
      //   return 0;
      return this.overview?.planning?.nb ?? 0;
    },
    nbMembershipTypes() {
      //   return 0;
      return this.overview?.membershipTypes?.nb ?? 0;
    },
    lastMembershipType() {
      return this.overview?.membershipTypes?.last;
    },
    nbClients() {
      // return 0;
      return this.overview?.clients?.nb ?? 0;
    },
    lastClient() {
      return this.overview?.clients?.last;
    },
    nbMemberships() {
      // return 0;
      return this.overview?.memberships?.nb ?? 0;
    },
    lastMembership() {
      return this.overview?.memberships?.last;
    },
  },
  async mounted() {
    await this.retrieveAll();
  },
  methods: {
    async retrieveAll() {
      // overview
      const overview = await this.$services.organization.getOverview();

      this.overview = overview.data;

      this.showFinished =
        this.nbLessons > 0 &&
        this.nbPlanning > 0 &&
        this.nbClients > 0 &&
        this.nbMembershipTypes > 0 &&
        this.nbMemberships > 0;

      this.showWelcome = this.alert;
    },
    async addLesson() {
      const user = await this.$store
        .dispatch("adminauth/fetchUser")
        .catch(() => { });

      this.$refs.lessonEdition.open({ userId: user.id, active: true });
    },
    async planningToCalendar() {
      if (!this.lastLesson) return;

      const lessonResponse = await this.$services.lesson.get(
        this.lastLesson.id
      );
      const lesson = lessonResponse.data;

      if (lesson.frequency) {
        this.generatePlanningClicked(lesson);
      } else {
        this.pickPlanningClicked(lesson);
      }
    },
    addMembershipType() {
      this.$refs.membershipTypeEdition.open({
        name: "Carte abonnement 10 séances",
        active: true,
        vatRate: 21,
        unit: 10,
        priceVatIncluded: 150,
        validityUnit: "month",
        validityValue: 3,
      });
    },
    generatePlanningClicked(lesson) {
      const toDate = this.$moment(new Date());
      toDate.add({ months: 1 });

      this.$refs.generatePlanningPopup.retrieveLessons();

      this.$refs.generatePlanningPopup.open({
        lessonId: lesson.id,
        lesson: lesson,
        userId: lesson.userId,
        color: lesson.color,
        dateBegin: new Date().toISOString().substr(0, 10),
        dateEnd: toDate.toDate().toISOString().substr(0, 10),
        hourBegin: lesson.timeBegin,
        hourEnd: lesson.timeEnd,
        // days: lesson.weekday ? [lesson.weekday.toString()] : [],
      });
    },
    pickPlanningClicked(lesson) {
      const toDate = this.$moment(new Date());
      toDate.add({ months: 1 });

      this.$refs.pickPlanningPopup.retrieveLessons();

      this.$refs.pickPlanningPopup.open({
        lessonId: lesson.id,
        userId: lesson.userId,
        color: lesson.color,
        dateBegin: new Date().toISOString().substr(0, 10),
        dateEnd: toDate.toDate().toISOString().substr(0, 10),
        hourBegin: lesson.timeBegin,
        hourEnd: lesson.timeEnd,
        dates: [],
      });
    },
    addClient() {
      this.$refs.clientEdition.open({
        active: true,
        language: this.$i18n.locale,
      });
    },
    async addMembership() {
      this.$refs.membershipEdition.retrieveMembershipTypes();
      this.$refs.membershipEdition.retrieveClients();

      if (!this.lastClient || !this.lastMembershipType) return;

      const membershipTypeResponse = await this.$services.membershipType.get(
        this.lastMembershipType.id
      );
      const membershipType = membershipTypeResponse.data;

      const newMembership = {
        membershipTypeId: membershipType.id,
        clientId: this.lastClient.id,
        active: true,
        priceVatIncluded: membershipType.priceVatIncluded,
        vatRate: membershipType.vatRate,
        payment: {
          status: 1,
          paymentTypeId: "cash",
          date: new Date(),
        },
      };

      this.$refs.membershipEdition.open(newMembership);
      this.$refs.membershipEdition.computeEndDate(membershipType);
    },
    savePlanningGenerationClicked() {
      this.retrieveAll();
    },
    savePlanningPickClicked() {
      this.retrieveAll();
    },
    saveLessonClicked() {
      this.retrieveAll();
    },
    saveMembershipTypeClicked() {
      this.retrieveAll();
    },
    saveClientClicked() {
      this.retrieveAll();
    },
    saveMembershipClicked() {
      this.retrieveAll();
    },
  },
};
</script>