<template>
  <v-container>
    <v-row align="center">
      <v-col class="shrink">
        <v-avatar size="96">
          <v-img
            lazy-src="@/assets/blank-profile-picture.png"
            :src="imageUrl"
            contain
          ></v-img>
        </v-avatar>
      </v-col>
      <v-col class="text-h4 font-weight-medium">
        {{ organizationName }}
        <v-btn v-if="user && user.main" plain :to="$helpers.router.getAdminLink('my-organization')"
          ><v-icon left small>mdi-eye</v-icon> voir les paramètres</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import fs from "fs";

export default {
  data: () => ({
    imageUrl: null,
    organization: null,
    user: null,
  }),
  computed: {
    organizationName() {
      return this.organization?.name;
    },
    organizationUrl() {
      return this.organization?.url;
    },
  },
  async mounted() {
    this.user = await this.$store
      .dispatch("adminauth/fetchUser")
      .catch(() => {});

    let organization = await this.$store.dispatch(
      "organization/fetchOrganization"
    );

    this.organization = organization;

    this.imageUrl = this.$store.getters["organization/getImageUrl"];
  },
};
</script>